<template>
  <vue3-tinymce disabled v-model='showContent' :setting="config.setting"/>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import Vue3Tinymce from '@jsdawn/vue3-tinymce'

export default defineComponent({
  name: 'Tinymce',
  components: {
    Vue3Tinymce,
  },
  props: {
    content: {
      default: () => '',
      type: String,
    },
  },
  setup(props) {
    const config = {
      setting: {
        inline: true, // 隐藏模式
        content_style: 'img {max-width:100%;}',
      },
    }

    const showContent = ref('')

    const watchFun = watch(props, () => {
      showContent.value = props.content
    }, { immediate: true, deep: true })

    return {
      watchFun,
      config,
      showContent,
    }
  },
})
</script>

<style scoped lang="scss">
</style>